import * as React from 'react'
import { Link } from 'gatsby'
import { SocialLinks } from '../../components/social-icons'
import ReactPlayer from 'react-player'

import { FaHome } from 'react-icons/fa'
import { Fade } from 'react-awesome-reveal'

import Seo from '../../components/seo'

const Site404 = () => (
	<>
		<Seo title="Forbidden | You don't have permission to access this page." />

		<div className="hero min-h-full overflow-hidden">
			<ReactPlayer
				url={'https://youtu.be/3xYXUeSmb-Y'}
				controls={false}
				playing
				loop
				muted
				playsinline
				width="100%"
				height="100vh"
			/>

			<div className="hero-overlay bg-opacity-80 z-10"></div>
			<div className="text-center min-h-screen bg-opacity-50 sm:bg-opacity-95 absolute bg-base-300 left-0 top-0 flex items-center z-20">
				<div className="max-w-2xl p-10 text-neutral-content">
					<Fade damping=".25" triggerOnce="true" cascade>
						<h1 className="mb-5 text-4xl md:text-6xl font-bold">
							Forbidden: You don't have permission to access this page.
						</h1>
						<p className="mb-5 text-xl py-4">
							Always remember, Frodo, the page is trying to get back to its
							master. It wants to be found.
						</p>
						<Link to="/" className="btn btn-lg btn-wide btn-primary">
							<FaHome className="pr-2 align-middle" size={25} />
							<span>Home</span>
						</Link>
						<div className="mx-auto w-full absolute left-0 bottom-10">
							<div className="flex sm:w-6/12 justify-evenly mx-auto gap-2">
								<SocialLinks></SocialLinks>
							</div>
						</div>
					</Fade>
				</div>
			</div>
		</div>
	</>
)

export default Site404
